<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="$t('tGroupDetail')"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('group.create_time')" prop="create_time">
          <div>{{ ruleForm.create_time }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.update_time')" prop="update_time">
          <div>{{ ruleForm.update_time }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.name')" prop="name">
          <div>{{ ruleForm.name }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.user_username')" prop="user.username">
          <div>{{ ruleForm.user.username }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.subject_name')" prop="subject.name">
          <div>{{ ruleForm.subject.name }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.chapter_name')" prop="chapter.name">
          <div>{{ ruleForm.chapter.name }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.timer')" prop="timer">
          <div>{{ ruleForm.timer }}</div>
        </el-form-item>
        <el-form-item :label="$t('group.type')" prop="type">
          <div>{{ type(ruleForm.type) }}</div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { groupDetail } from "@/api/index";
export default {
  name: "groupDetail",
  data() {
    return {
      ruleForm: {
          id:'',
        user:{
            id:'',
            username:''
        },
        subject:{
            id:'',
            name:''
        },
        chapter:{
            id:'',
            name:''
        },
        timer:'',
        name:'',
        type:0,
        create_time:'',
        update_time:''
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    }
  },
  methods: {
      type(type) {
      switch (type) {
        case 1:
          return this.$t("group.type1");
        case 2:
          return this.$t("group.type2");
      }
    },
    async get_info() {
      const information = await groupDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
